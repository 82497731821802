import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect
} from "react";
import { Data, Overrides, Events } from "@transformd-ltd/sdk";
import { Helmet } from "react-helmet";
import "@shwilliam/react-rubber-slider/dist/styles.css";
import { Formatic, CustomCheckboxList, CustomRadioList } from "../components";

const formProps = {
  apiServerUrl: "https://api-staging.transformd.com",
  serverUrl: "https://api-staging.transformd.com/graphql",
  subscriptionServerUrl: "wss://api-staging.transformd.com/subscriptions"
};

export type EmitterData = {
  fieldID: string;
  newValue: string | string[];
  oldValue: string | string[];
};

export type FormData = {
  [key: string]: any;
  getEmitter: () => any;
};

const RealEstatePage: FunctionComponent = (): ReactElement => {
  const [page, setPage] = useState<string>("6296a144f6dea600a65d23f9");
  const [data] = useState<FormData>(new Data());

  const inputChangeListener = () => {
    const emitter = data?.getEmitter();

    // Listen to page render events and set id of current page
    emitter.on(Events.PageRender, page => {
      setPage(page.id);
    });
  };

  useEffect(() => {
    inputChangeListener();
  }, []);

  return (
    <div
      className="page__example-fsf dark font-serif-finance flex min-h-screen justify-center bg-fixed px-5 pt-5 md:px-8 md:pt-8"
      id="main"
      style={{
        backgroundImage:
          "linear-gradient(0deg, #E8E7EC 0%, #E8E7EC 40%, #F9CBA9 40%, #F9CBA9 100%)"
      }}
    >
      <Helmet title="APLYiD Onboarding - Real Estate Demo" defer={false} />
      <div className="b-white m-4 w-full max-w-5xl space-y-4 bg-white/30 px-16">
        <div className="flex w-full items-center justify-between py-4">
          <div className="cursor-pointer text-[#DB391E] text-xl">
            Fin<span className="text-[#5A1F14]">ance</span>
          </div>
          <div>
            <ul className="flex items-center space-x-8 text-sm">
              <li className="cursor-pointer">How it works</li>
              <li className="cursor-pointer">Loans</li>
              <li className="cursor-pointer">Contact us</li>
              <li className="w-10" />
              <li className="cursor-pointer">Login</li>
              <li className="cursor-pointer bg-white p-4 hover:bg-white/60">
                Sign Up!
              </li>
            </ul>
          </div>
        </div>
        <div className="flex w-full space-x-4">
          <div className="flex flex-1 items-center">
            <div className="space-y-4">
              <p className="text-[#5A1F14]">LOANS UP TO $50K!</p>
              <h1 className="font-bold text-[#DB391E] text-4xl">
                Easiest place to apply for your loan
              </h1>
              <p className="text-gray-600">
                Need some fast cash? Bad credit history? Try Finance Company and
                feel secure in your future
              </p>
            </div>
          </div>
          <div className="pointer-events-none flex flex-1 items-center justify-end">
            <img
              src="/img/background/finance.png"
              className="-mt-14 -mb-12 w-3/4"
            />
          </div>
        </div>
        <div className="flex w-full justify-center rounded-lg bg-white p-8">
          <div className="w-full max-w-md space-y-4">
            {/* {page} */}
            {page === "6296a144f6dea600a65d23f9" && (
              <h3 className="text-[#DB391E] text-2xl">Apply now</h3>
            )}
            {page === "6296a2cef137d200ab28d238" && (
              <h3 className="text-[#DB391E] text-2xl">Do you qualify?</h3>
            )}
            {page === "6296a3aaf137d200ad1a5ad6" && (
              <h3 className="text-[#DB391E] text-2xl">
                Tell us a bit about yourself
              </h3>
            )}
            {page === "6296afa6f137d200aa75aa7b" && (
              <div>
                <h3 className="text-[#DB391E] text-2xl">Thank you!</h3>
                <p>
                  We have received your details, the next step is lorem ipsum
                  dolor sit amet.
                </p>
                <div className="rounded-xl bg-slate-400 pt-4">
                  <img
                    src="/img/icons/biometrics.svg"
                    className="h-auto w-full rounded-xl"
                  />
                </div>
              </div>
            )}
            <Formatic
              {...formProps}
              data={data}
              formId={592}
              apiKey="afdcDf159365F9d9A6d12E334be147562A97F2B2b3a46D6A15F0cF611240Ab63"
              environment="Staging"
              channel="master"
              initialValues={{}}
              config="default"
              theme="transformd"
            >
              <Overrides.OverrideFieldContainer
                component={CustomCheckboxList}
                type="checkboxList"
              />
              <Overrides.OverrideFieldContainer
                component={CustomRadioList}
                type="radioList"
              />
            </Formatic>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstatePage;
